export async function fetchData(): Promise<any[]> {
    const options = {method: 'GET'};

    const dadosML = fetch( process.env.REACT_APP_BASE_URL + '/v1/totalizador?site=ML', options)

    const dadosSH = fetch(process.env.REACT_APP_BASE_URL + '/v1/totalizador?site=SH', options)

    const dadosTableML = fetch(process.env.REACT_APP_BASE_URL + '/v1/tabelaportipo', options)

    const dadosTableSH = fetch(process.env.REACT_APP_BASE_URL + '/v1/tabelaportipo?site=SH', options)

    const recorrenciaML = fetch(process.env.REACT_APP_BASE_URL + '/v1/totalizadorportempo', options)

    const afiliadoML = fetch(process.env.REACT_APP_BASE_URL + '/v1/totalizadorporafiliado', options)

    const assinantesML = fetch(process.env.REACT_APP_BASE_URL + '/v1/totalizadorsetedias', options)

    const afiliadoSH = fetch(process.env.REACT_APP_BASE_URL + '/v1/totalizadorporafiliado?site=SH', options)

    const assinantesSH = fetch(process.env.REACT_APP_BASE_URL + '/v1/totalizadorsetedias?site=SH', options)

    const pfpjML = fetch(process.env.REACT_APP_BASE_URL + '/v1/totalizadorpfpj?site=ML', options)

    const pfpjSH = fetch(process.env.REACT_APP_BASE_URL + '/v1/totalizadorpfpj?site=SH', options)

    const webSumit = fetch(process.env.REACT_APP_BASE_URL + '/v1/totalizadormes', options)

    const ltv = fetch(process.env.REACT_APP_BASE_URL + '/v1/totalizadorltv', options)

    const todosDados = await Promise.all([dadosML, dadosSH, dadosTableML,recorrenciaML,afiliadoML,assinantesML,dadosTableSH,afiliadoSH,assinantesSH,pfpjML,pfpjSH,webSumit,ltv]);

    const results = await Promise.all(todosDados.map(response => response.json()));

    return results;
  }