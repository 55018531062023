import { Col, Container, Row, Table } from "react-bootstrap"
import { Header, LegendaConv, LogoMLSVG, Titulo, TituloCard } from "./style"
import { Card } from "../../components/card/Card"
import { MLxAvantpro } from "../../components/Grafico/MLxAvant"
import { CpfXCnpj } from "../../components/Grafico/cpfXcnpj"
import { RecorrenciaGrafML } from "../../components/Grafico/RecorrenciaML"
import { VendasInfluenciadores } from "../../components/Grafico/InfluenciadoresML"
import { useEffect, useState } from "react"
import { AssinaturasGraf } from "../../components/Grafico/Assinaturas"
import { fetchDataML } from "./PromisseAllML"
import { useNavigate } from "react-router-dom"
import { SetaSVG } from "../PaginaSH/style"
import { TooltipBottom, TooltipTop } from "../../components/Tooltip/Tooltip.style"
import { LTVml } from "../../components/Grafico/LTVml"

export const PaginaML = () => {

    const navegate = useNavigate();
    const [dadosML, setDadosML] = useState<any[]>([]);
    const [dadosTableML, setdadosTableML] = useState<any[]>([]);
    const [RecorrenciaML, SetRecorrenciaML] = useState<any[]>([]);
    const [AfiliaoML, SetAfiliadoML] = useState<any[]>([]);
    const [assinantesML, SetAssinantesML] = useState<any[]>([]);
    const [dadospfML, SetDadospfML] = useState<any[]>([]);
    const [dadosSummit, setDadosSummit] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [mostrarDados, setMostrarDados] = useState(false);
    const [dadosLtv, setDadosLtv] = useState<any[]>([]);
    const [afiliadoNovoML, setAfiliadoNovoML] = useState<any[]>([]);
    const cores = ['#F18F01','#048BA8','#2E4057','#99C24D']

    useEffect(() => {
        async function getData() {
            const result = await fetchDataML();
            console.log(result);
            setLoading(false)
            setMostrarDados(false)

            if(result.length > 0) {
                setMostrarDados(true)
            }

            setDadosML(result[0].RESULT)
            setdadosTableML(result[1].RESULT)
            SetRecorrenciaML(result[2].RESULT)
            SetAfiliadoML(result[3].RESULT)
            SetAssinantesML(result[4].RESULT)
            SetDadospfML(result[5].RESULT)
            setDadosSummit(result[6].RESULT)
            setDadosLtv(result[7].RESULT)
            setAfiliadoNovoML(result[8].RESULT)

        }
        getData();
 
    }, []);

    function paraSH(){
        navegate("/SH");
    }

    return (
        <Container fluid >
            <Row className="pt-2" style={{position:"relative"}}>
                <Col> {/* COMEÇO DO AVANTPRO ML*/}
                <Row > {/* Header*/} 
                    <Col xs={12} ><Header><LogoMLSVG /><h1>Avantpro ML</h1></Header></Col>
                    <Col xs={12} style={{display:'flex',justifyContent:'flex-end'}}><div style={{cursor:'pointer'}} onClick={paraSH}> Dados Shopee <SetaSVG /></div></Col>
                </Row>
                        <Col style={{display:loading ? 'flex':'none',justifyContent:'center',width:'100%',marginBottom:20}}>
                            <div className="spinner-border text-warning" role="status">
                                <span className="visually-hidden">Loading...</span> 
                            </div>
                            <span style={{marginLeft:20,marginTop:'auto',marginBottom:'auto'}}>Carregando Dados..</span>
                        </Col>
                    <Row className="row-cols-2 row-cols-md-5 row-cols-sm-2" style={{height:"auto",visibility:mostrarDados ? 'visible':'hidden'}}> {/* Inicio Informaçoes Gerais ML*/}
                        {dadosML.map(item => (
                            <Col style={{marginBottom:10,paddingRight:5}}>
                                <Card style={{paddingTop:10,marginBottom:1,marginRight:'auto',marginLeft:'auto'}}>
                                    <h6 className="placeholder-glow" style={{color:'gray',marginBottom:1,fontSize:14}}>{(item.STATUSNAME).toUpperCase()}</h6>
                                    <h4 className="placeholder-glow" style={{marginBottom:1,fontSize:20}}>{(item.TOTAL).toLocaleString()}</h4>
                                </Card>
                            </Col>
                        ))
                        }
                    </Row>{/* Termino Informaçoes Gerais*/}
                    <Row style={{height:"auto",visibility:mostrarDados ? 'visible':'hidden'}}> {/* Inicio da Tabela Assinantes/Plano/Status ML*/}
                        <Col>
                        <Titulo style={{marginBottom:10,textAlign:'center'}}> TABELA ASSINANTES/PLANO/STATUS </Titulo>
                            <Card style={{borderRadius:15, marginTop:10,padding:"5px 5px",overflowX:'auto'}}>
                                <Table className="table table-rounded" style={{width:'100%',marginBottom:0}}>
                                <thead>
                                    <tr style={{fontSize:10,textAlign:"center",verticalAlign:"middle"}}>
                                        <th style={{ borderRight: '1px solid lightgray'}} scope="col">#</th>
                                        <th style={{ borderRight: '1px solid lightgray'}} scope="col">PAGO</th>
                                        <th style={{ borderRight: '1px solid lightgray'}} scope="col">CANCELADO</th>
                                        <th style={{ borderRight: '1px solid lightgray'}} scope="col">REEMBOLSADO</th> 
                                        <th style={{ borderRight: '1px solid lightgray'}} scope="col">AGUARD. PAG</th>
                                        <th style={{ borderRight: '1px solid lightgray'}} scope="col">TRIAL</th>
                                        <th style={{ borderRight: '1px solid lightgray'}} scope="col">ATRASADO</th>
                                        <th style={{ borderRight: '1px solid lightgray'}} scope="col">SUSPENSO</th>
                                        <th style={{ borderRight: '1px solid lightgray'}} scope="col">FINALIZADO</th>
                                        <th scope="col">AGUARD. REEMB</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dadosTableML.map((item,i) =>(
                                        <>
                                            <tr style={{fontSize:11,textAlign:"center",verticalAlign:"middle",fontWeight:500}}>
                                                <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableML.length - 1 ? 'none': '',fontSize:12}} >{(item.TAG).replace('_', ".")}</td>
                                                <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableML.length - 1 ? 'none': '',fontSize:12}} >{Intl.NumberFormat('pt-br').format(item.PAGO)}</td>
                                                <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableML.length - 1 ? 'none': '',fontSize:12}} >{Intl.NumberFormat('pt-br').format(item.CANCELADO)}</td>
                                                <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableML.length - 1 ? 'none': '',fontSize:12}} >{(item.REEMBOLSADO)}</td>
                                                <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableML.length - 1 ? 'none': '',fontSize:12}} >{(item.AGUARDANDO_PAGAMENTO)}</td>
                                                <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableML.length - 1 ? 'none': '',fontSize:12}} >{(item.TRIAL)}</td>
                                                <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableML.length - 1 ? 'none': '',fontSize:12}} >{(item.ATRASADO)}</td>
                                                <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableML.length - 1 ? 'none': '',fontSize:12}} >{(item.SUSPENSO)}</td>
                                                <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableML.length - 1 ? 'none': '',fontSize:12}} >{(item.FINALIZADO)}</td>
                                                <td style={{ borderBottom:i === dadosTableML.length - 1 ? 'none': '',fontSize:12}}>{(item.AGUARDANDO_REEMBOLSO)}</td>
                                            </tr>
                                        </>
                                    ))}
                                </tbody>
                                </Table> 
                            </Card>
                        </Col>
                    </Row>{/* Termino da Tabela Assinantes/Plano/Status*/}
                    <Row style={{height:"auto",marginTop:20,visibility:mostrarDados ? 'visible':'hidden'}}> {/* Inicio da Tabela MRR - CHRUN - CAC*/}
                        <Col>
                        <Titulo style={{marginBottom:10,textAlign:'center'}}> MRR - CHURN - CAC - ATIVOS - CANCELAMENTOS </Titulo>
                            <Card style={{borderRadius:'15px 10px', marginTop:10,padding:"5px 5px",overflowX:'auto',marginRight:-20}}>
                                <Table className="table table-rounded" style={{marginBottom:0,marginRight:-20}}>
                                <thead>
                                    <tr style={{fontSize:10,textAlign:"center",verticalAlign:"middle"}}>
                                        <th style={{ borderRight: '1px solid lightgray'}} scope="col">#</th>
                                        {dadosSummit.map((item,i) =>(
                                            <th style={{ borderRight:i === dadosSummit.length - 1 ? 'none': '1px solid lightgray',color:i === dadosSummit.length - 1 ? '#FC9F2D': 'Black'}} scope="col">{item.MES}/{item.ANO}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{fontSize:11,textAlign:"center",verticalAlign:"middle",fontWeight:500}}>
                                        <td style={{ borderRight: '1px solid lightgray',borderBottom: '1px solid lightgray',fontSize:12}} >MRR</td>
                                        {dadosSummit.map((item,i) =>(
                                            <th style={{ borderRight:i === dadosSummit.length - 1 ? 'none': '1px solid lightgray',color:i === dadosSummit.length - 1 ? '#FC9F2D': 'Black'}} scope="col">{Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(item.MRR)}</th>
                                        ))}
                                    </tr>
                                    <tr style={{fontSize:11,textAlign:"center",verticalAlign:"middle",fontWeight:500}}>
                                        <td style={{ borderRight: '1px solid lightgray',borderBottom: '1px solid lightgray',fontSize:12}} >ATIVOS</td>
                                        {dadosSummit.map((item,i) =>(
                                            <th id={item.CODIGO}  style={{ borderRight:i === dadosSummit.length - 1 ? 'none': '1px solid lightgray',position:"relative",color:i === dadosSummit.length - 1 ? '#FC9F2D': 'Black'}} scope="col"><TooltipTop style={{display:'grid',fontSize:12}}> <span style={{fontWeight:500}}>Ativos Referentes ao mês Anterior</span> <span style={{fontWeight:500}}>Venda Anual: {Intl.NumberFormat('pt-br').format(item.VENDASA)}</span> <span style={{fontWeight:500}}>Venda Mensal: {Intl.NumberFormat('pt-br').format(item.VENDASM)}</span></TooltipTop>{Intl.NumberFormat('pt-br').format(item.VENDAS)}</th>                                    
                                        ))}
                                    </tr>
                                    <tr style={{fontSize:11,textAlign:"center",verticalAlign:"middle",fontWeight:500}}>
                                        <td style={{ borderRight: '1px solid lightgray',borderBottom: '1px solid lightgray',fontSize:12}} >CANCELADOS</td>
                                        {dadosSummit.map((item,i) =>(
                                            <th id={item.CODIGO}  style={{ borderRight:i === dadosSummit.length - 1 ? 'none': '1px solid lightgray',position:"relative",color:i === dadosSummit.length - 1 ? '#FC9F2D': 'Black'}} scope="col"><TooltipBottom style={{display:'grid',fontSize:12}}> <span style={{fontWeight:500}}>Canc. Referentes ao mês Corrente</span> <span style={{fontWeight:500}}>Canc. Anual: {Intl.NumberFormat('pt-br').format(item.CANCELADOA)}</span> <span style={{fontWeight:500}}>Canc. Mensal: {Intl.NumberFormat('pt-br').format(item.CANCELADOM)}</span></TooltipBottom>{Intl.NumberFormat('pt-br').format(item.CANCELADO)}</th>                                    
                                        ))}
                                    </tr>
                                    <tr style={{fontSize:11,textAlign:"center",verticalAlign:"middle",fontWeight:500}}>
                                        <td style={{ borderRight: '1px solid lightgray',borderBottom: '1px solid lightgray',fontSize:12}} >CHURN</td>
                                        {dadosSummit.map((item,i) =>(
                                            <th style={{ borderRight:i === dadosSummit.length - 1 ? 'none': '1px solid lightgray',color:i === dadosSummit.length - 1 ? '#FC9F2D': 'Black'}} scope="col">{item.CHURN}%</th>
                                        ))}
                                    </tr>
                                    <tr style={{fontSize:11,textAlign:"center",verticalAlign:"middle",fontWeight:500}}>
                                        <td style={{ borderRight: '1px solid lightgray',borderBottom: 'none',fontSize:12}} >CAC</td>
                                        {dadosSummit.map((item,i) =>(
                                            <th style={{ borderRight:i === dadosSummit.length - 1 ? 'none': '1px solid lightgray',borderBottom: 'none',color:i === dadosSummit.length - 1 ? '#FC9F2D': 'Black'}} scope="col">{Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(item.CAC)}</th>
                                        ))}
                                    </tr>
                                </tbody>
                                </Table> 
                            </Card>
                        </Col>
                    </Row>{/* Termino da Tabela MRR - CHRUN - CAC */}
                    <Row style={{height:"auto",marginTop:20,visibility:mostrarDados ? 'visible':'hidden'}}> {/* Inicio Tabela Novos Ass. ML*/}
                        <Col>
                            <Titulo style={{marginBottom:1,textAlign:'center'}}> NOVOS ASSINANTES NOS ÚLTIMOS 14 DIAS </Titulo>
                            <Card style={{borderRadius:15, marginTop:10,padding:"5px 5px",overflowX:'auto'}}>
                                <Table className="table table-rounded" style={{width:'100%',marginBottom:0}}>
                                <thead>
                                    <tr style={{fontSize:10,textAlign:"center",verticalAlign:"middle"}} >
                                        <th style={{ borderRight: '1px solid lightgray',fontSize:13}}>2023</th>
                                        {assinantesML.map((item,i) =>(
                                            <th style={{fontSize:12,borderRight:i === dadosTableML.length - (-9) ? 'none': '1px solid lightgray'}}>{(item.DATA).replace('/2023','')}</th>
                                        ))}
                                </tr>
                                </thead>
                                <tbody>
                                    <tr style={{fontSize:10,textAlign:"center",verticalAlign:"middle"}} >
                                        <td style={{ borderRight: '1px solid lightgray',borderBottom:'none',fontSize:13}}>NOVOS ASSINANTES</td>
                                        {assinantesML.map((item,i) =>(
                                            <td style={{fontSize:12,borderRight:i === dadosTableML.length - (-9) ? 'none': '1px solid lightgray',borderBottom:'none'}}>{item.SOMA}</td>
                                        ))}                              
                                    </tr>
                                </tbody>
                                </Table> 
                            </Card>
                        </Col>
                    </Row> {/* Termino Tabela Pag.ok ML */}
                    <Row className="row-cols-1 row-cols-md-2 row-cols-sm-3" style={{height:"auto",marginTop:20,visibility:mostrarDados ? 'visible':'hidden'}}> {/* Inicio GraficosML */}
                        <Col>
                            <Titulo style={{marginBottom:1,textAlign:'center'}}> VENDEDORES ML X USUARIOS AVANTPROML </Titulo>
                            <Card style={{height:"200px",marginTop:15,display:'flex',width:'100%',paddingRight:5,paddingLeft:5}}> 
                                <div style={{height:'100%',marginTop:'auto',marginBottom:'auto'}}>
                                    <MLxAvantpro />
                                </div>                           
                                <div style={{display: "grid", justifyContent: "center",alignItems:'center',justifyItems:'center',marginTop:'auto',marginBottom:'auto',width:'100%'}}>
                                    <TituloCard style={{display:"flex", alignItems:"baseline"}}><LegendaConv style={{backgroundColor:'#FFA200'}}/><h4 style={{fontWeight:400,display:'flex',flexDirection:"column",alignItems:'center',fontSize:15}}>VENDEDORES ML <span style={{fontWeight:500}}>10Milhões</span></h4></TituloCard>
                                    <TituloCard style={{display:"flex", alignItems:"baseline"}}><LegendaConv style={{backgroundColor:'rgba(54, 162, 235, 1)'}}/><h4 style={{fontWeight:400,display:'flex',flexDirection:"column",alignItems:'center',fontSize:15}}>USUÁRIOS AVANTPROML <span style={{fontWeight:500}}>{dadosML.length && (<span key={dadosML[0].TOTAL}>{(dadosML[0].TOTAL).toLocaleString()}</span>)}</span></h4></TituloCard>
                                </div>
                            </Card>
                        </Col>
                        <Col>
                            <Titulo style={{marginBottom:1,textAlign:'center'}}> ASSINATURAS </Titulo>
                            <Card style={{height:"200px",marginTop:15,display:'flex',justifyContent:'space-around',width:'100%'}}> 
                                <div style={{height:'100%',marginTop:'auto',marginBottom:'auto'}}>
                                    <AssinaturasGraf dados={{
                                      dados: dadosTableML,
                                      color: cores
                                  }} />
                                </div>
                                <div>
                                    {dadosTableML.map((item,i) => (
                                        <>  
                                            <h4 style={{marginBottom:1,fontSize:20}}>{(item.PAGO).toLocaleString()}</h4>
                                            <h6 style={{color:'gray',marginBottom:5,display:"flex"}}><LegendaConv style={{backgroundColor:cores[i]}}/>{item.TAG}</h6>
                                        </>
                                    ))}
                                </div>                           
                            </Card>
                        </Col>
                        <Col style={{display:'none'}}>
                            <Titulo style={{marginBottom:1,textAlign:'center'}}> VENDEDORES CPF X VENDEDORES CNPJ </Titulo>
                            <Card style={{height:"200px",marginTop:15,display:'flex',width:'100%'}}> 
                                <div style={{height:'100%',marginTop:'auto',marginBottom:'auto'}}>
                                    <CpfXCnpj dados={{
                                      dados: dadospfML
                                  }} />
                                </div>                           
                                <div style={{display: "grid", justifyContent: "center",alignItems:'center',justifyItems:'center',marginTop:'auto',marginBottom:'auto',width:'100%'}}>
                                    <TituloCard style={{display:"flex", alignItems:"baseline"}}><LegendaConv style={{backgroundColor:'rgba(255, 99, 132, 1)',marginRight:-2}}/><h4 style={{fontWeight:400,display:'flex',flexDirection:"column",alignItems:'center',fontSize:20}}>CPF <span style={{fontWeight:500}}>{dadospfML.length && (<span key={dadospfML[0].COUNT}>{(dadospfML[0].COUNT).toLocaleString()}</span>)}</span></h4></TituloCard>
                                    <TituloCard style={{display:"flex", alignItems:"baseline"}}><LegendaConv style={{backgroundColor:'rgba(54, 162, 235, 1)'}}/><h4 style={{fontWeight:400,display:'flex',flexDirection:"column",alignItems:'center',fontSize:20}}>CNPJ <span style={{fontWeight:500}}>{dadospfML.length && (<span key={dadospfML[1].COUNT}>{(dadospfML[1].COUNT).toLocaleString()}</span>)}</span></h4></TituloCard>
                                </div>
                            </Card>
                        </Col>
                    </Row>{/* Termino GraficosML */}                   
                    <Row className="row-cols-1 row-cols-md-2 row-cols-sm-2" style={{height:"auto",marginTop:20,visibility:mostrarDados ? 'visible':'hidden'}}> {/* Inicio Graf. Recorrencia / LTV */}
                        <Col>
                            <Titulo style={{marginBottom:1,textAlign:'center'}}> RECORRÊNCIA ASSINANTES </Titulo>
                            <Card style={{borderRadius:15, marginTop:10,padding:"5px 5px",height:'600px'}}>
                                <RecorrenciaGrafML 
                                dados={{
                                    dados:RecorrenciaML
                                }}/>
                            </Card>
                        </Col>
                        <Col >
                            <Titulo style={{marginBottom:1,textAlign:'center'}}> LTV </Titulo>
                            <Card style={{borderRadius:15, marginTop:10,padding:"5px 5PX",display:'flex',height:'600px'}}>
                                <LTVml
                                    dados={{
                                        dados:dadosLtv
                                    }}/>
                            </Card>
                        </Col>
                    </Row> {/* FIM Graf. Recorrencia / LTV*/}
                    <Row className="row-cols-1 row-cols-md-2 row-cols-sm-2" style={{height:"auto",marginTop:20,visibility:mostrarDados ? 'visible':'hidden'}}> {/* Inicio Graf Influenciadores ML / Afiliados ML*/}
                        <Col >
                            <Titulo style={{marginBottom:1,textAlign:'center'}}> VENDAS AFILIADOS </Titulo>
                            <Card style={{borderRadius:15, marginTop:10,padding:"5px 5PX",display:'flex',height:'750px'}}>
                                <VendasInfluenciadores
                                    dados={{
                                        dados:afiliadoNovoML
                                    }}/>
                            </Card>
                        </Col>
                        <Col >
                            <Titulo style={{marginBottom:1,textAlign:'center'}}> VENDAS INFLUENCIADORES </Titulo>
                            <Card style={{borderRadius:15, marginTop:10,padding:"5px 5PX",display:'flex',height:'600px'}}>
                                <VendasInfluenciadores
                                    dados={{
                                        dados:AfiliaoML
                                    }}/>
                            </Card>
                        </Col>
                    </Row> {/* FIM Influenciadores ML / Afiliados ML */}
                </Col> {/* Termino DO AVANTPRO ML*/}
            </Row>
        </Container>
    )
}
