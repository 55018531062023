import{
    BrowserRouter as Router,
    Route,
    Routes,
} from 'react-router-dom';
import React from "react"
import DashBoard from '../Pages/Dashboard';
import { PaginaML } from '../Pages/PaginaML';
import { PaginaSH } from '../Pages/PaginaSH';

const AppRoutes = () => {
    return (
        <Router>
                <Routes>               
                    <Route path='/' element={<DashBoard />}></Route>
                    <Route path='/ML' element={<PaginaML />}></Route>
                    <Route path='/Sh' element={<PaginaSH />}></Route>
                </Routes>
        </Router>
    );
}
export default AppRoutes;