import React, { useEffect, useState } from 'react';
import { Header, LegendaConv, LogoMLSVG, LogoSHSVG, Titulo, TituloCard } from './style';
import { fetchData } from './PromisseAll';
import { Card } from '../../components/card/Card';
import { Table } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { MLxAvantpro } from '../../components/Grafico/MLxAvant';
import { CpfXCnpj } from '../../components/Grafico/cpfXcnpj';
import { SHxAvantpro } from '../../components/Grafico/SHxAvant';
import { RecorrenciaGrafML } from '../../components/Grafico/RecorrenciaML';
import { VendasInfluenciadores } from '../../components/Grafico/InfluenciadoresML';
import { VendasInfluenciadoresSH } from '../../components/Grafico/influenciadoresSH';
import { CpfXCnpjSH } from '../../components/Grafico/pfxpjSH';
import { useNavigate } from 'react-router-dom';


function DashBoard() {

    const navegate = useNavigate();
    const [dadosML, setDadosML] = useState<any[]>([]);
    const [dadosSH, setDadosSH] = useState<any[]>([]);
    const [dadosTableML, setdadosTableML] = useState<any[]>([]);
    const [dadosTableSH, setdadosTableSH] = useState<any[]>([]);
    const [RecorrenciaML, SetRecorrenciaML] = useState<any[]>([]);
    const [AfiliaoML, SetAfiliadoML] = useState<any[]>([]);
    const [AfiliaoSH, SetAfiliadoSH] = useState<any[]>([]);
    const [assinantesML, SetAssinantesML] = useState<any[]>([]);
    const [assinantesSH, SetAssinantesSH] = useState<any[]>([]);
    const [dadospfML, SetDadospfML] = useState<any[]>([]);
    const [dadospfSH, SetDadospfSH] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [mostrarDados, setMostrarDados] = useState(false);
    

    useEffect(() => {
        async function getData() {
            const result = await fetchData();
            setLoading(false)
            setMostrarDados(false)
            console.log(result)
            if(result.length > 0) {
                setMostrarDados(true)
            }

            setDadosML(result[0].RESULT)
            setDadosSH(result[1].RESULT)
            setdadosTableML(result[2].RESULT)
            SetRecorrenciaML(result[3].RESULT)
            SetAfiliadoML(result[4].RESULT)
            SetAssinantesML(result[5].RESULT)
            setdadosTableSH(result[6].RESULT)
            SetAfiliadoSH(result[7].RESULT)
            SetAssinantesSH(result[8].RESULT)
            SetDadospfML(result[9].RESULT)
            SetDadospfSH(result[10].RESULT)
            

        }
        getData();
    }, []);

    function paraSH(){
        navegate("/SH");
    }
    function paraML(){
        navegate("/ML");
    }

  return (
    <Container fluid >
        <Row className="pt-2" style={{position:"relative"}}>
            <Col xs={6} className="bl"> {/* COMEÇO DO AVANTPRO ML*/}
            <Header><LogoMLSVG /><h1 onClick={paraML} style={{cursor:'pointer'}}> Avantpro ML</h1></Header>
                    <Col style={{display:loading ? 'flex':'none',justifyContent:'center',width:'100%',marginBottom:20}}> {/* Loading */}
                        <div className="spinner-border text-warning" role="status">
                            <span className="visually-hidden">Loading...</span> 
                        </div>
                        <span style={{marginLeft:20,marginTop:'auto',marginBottom:'auto'}}>Carregando Dados..</span>
                    </Col>
                <Row className="row-cols-1 row-cols-md-5 row-cols-sm-2" style={{height:"auto",visibility:mostrarDados ? 'visible':'hidden'}}> {/* Inicio Informaçoes Gerais ML*/}
                    {dadosML.map(item => (
                            <Col style={{marginBottom:10,paddingRight:5}}>
                                <Card style={{paddingTop:10,marginBottom:1,marginRight:'auto',marginLeft:'auto'}}>
                                    <h6 className="placeholder-glow" style={{color:'gray',marginBottom:1,fontSize:14}}>{(item.STATUSNAME).toUpperCase()}</h6>
                                    <h4 className="placeholder-glow" style={{marginBottom:1,fontSize:20}}>{(item.TOTAL).toLocaleString()}</h4>
                                </Card>
                            </Col>
                        ))
                        }
                </Row>{/* Termino Informaçoes Gerais*/}
                <Row style={{height:"auto",visibility:mostrarDados ? 'visible':'hidden'}}> {/* Inicio da Tabela Assinantes/Plano/Status ML*/}
                    <Col>
                    <Titulo style={{marginBottom:10,textAlign:'center'}}> TABELA ASSINANTES/PLANO/STATUS </Titulo>
                        <Card style={{borderRadius:15, marginTop:10,padding:"5px 5px"}}>
                            <Table className="table table-rounded" style={{width:'100%',marginRight:200,marginBottom:0}}>
                            <thead>
                                <tr style={{fontSize:10,textAlign:"center",verticalAlign:"middle"}}>
                                    <th style={{ borderRight: '1px solid lightgray'}} scope="col">#</th>
                                    <th style={{ borderRight: '1px solid lightgray'}} scope="col">PAGO</th>
                                    <th style={{ borderRight: '1px solid lightgray'}} scope="col">CANCELADO</th>
                                    <th style={{ borderRight: '1px solid lightgray'}} scope="col">REEMBOLSADO</th> 
                                    <th style={{ borderRight: '1px solid lightgray'}} scope="col">AGUAR.PAG</th>
                                    <th style={{ borderRight: '1px solid lightgray'}} scope="col">TRIAL</th>
                                    <th style={{ borderRight: '1px solid lightgray'}} scope="col">ATRASADO</th>
                                    <th style={{ borderRight: '1px solid lightgray'}} scope="col">SUSPENSO</th>
                                    <th style={{ borderRight: '1px solid lightgray'}} scope="col">FINALIZADO</th>
                                    <th scope="col">AGUAR. REEMB</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dadosTableML.map((item,i) =>(
                                    <>
                                        <tr style={{fontSize:11,textAlign:"center",verticalAlign:"middle",fontWeight:500}}>
                                            <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableML.length - 1 ? 'none': '',fontSize:12}} >{(item.TAG).replace('_', ".")}</td>
                                            <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableML.length - 1 ? 'none': '',fontSize:12}} >{(item.PAGO)}</td>
                                            <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableML.length - 1 ? 'none': '',fontSize:12}} >{(item.CANCELADO)}</td>
                                            <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableML.length - 1 ? 'none': '',fontSize:12}} >{(item.REEMBOLSADO)}</td>
                                            <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableML.length - 1 ? 'none': '',fontSize:12}} >{(item.AGUARDANDO_PAGAMENTO)}</td>
                                            <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableML.length - 1 ? 'none': '',fontSize:12}} >{(item.TRIAL)}</td>
                                            <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableML.length - 1 ? 'none': '',fontSize:12}} >{(item.ATRASADO)}</td>
                                            <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableML.length - 1 ? 'none': '',fontSize:12}} >{(item.SUSPENSO)}</td>
                                            <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableML.length - 1 ? 'none': '',fontSize:12}} >{(item.FINALIZADO)}</td>
                                            <td style={{ borderBottom:i === dadosTableML.length - 1 ? 'none': '',fontSize:12}}>{(item.AGUARDANDO_REEMBOLSO)}</td>
                                        </tr>
                                    </>
                                ))}
                            </tbody>
                            </Table> 
                        </Card>
                    </Col>
                </Row>{/* Termino da Tabela Assinantes/Plano/Status*/}
                <Row style={{height:"auto",marginTop:10,visibility:mostrarDados ? 'visible':'hidden'}}> {/* Inicio Tabela Novos Ass. ML*/}
                    <Col>
                        <Titulo style={{marginBottom:1,textAlign:'center'}}> NOVOS ASSINANTES NOS ÚLTIMOS DIAS </Titulo>
                        <Card style={{borderRadius:15, marginTop:10,padding:"5px 5px"}}>
                            <Table className="table table-rounded" style={{width:'100%',marginBottom:0}}>
                            <thead>
                                <tr style={{fontSize:10,textAlign:"center",verticalAlign:"middle"}} >
                                    <th style={{ borderRight: '1px solid lightgray',fontSize:13}}>2023</th>
                                    {assinantesML.map((item,i) =>(
                                        <th style={{fontSize:12,borderRight:i === dadosTableML.length - (-9) ? 'none': '1px solid lightgray'}}>{(item.DATA).replace('/2023','')}</th>
                                    ))}
                               </tr>
                            </thead>
                            <tbody>
                                <tr style={{fontSize:10,textAlign:"center",verticalAlign:"middle"}} >
                                    <td style={{ borderRight: '1px solid lightgray',borderBottom:'none',fontSize:13,fontWeight:600}}>NOVOAS ASS.</td>
                                    {assinantesML.map((item,i) =>(
                                        <td style={{fontSize:12,borderRight:i === dadosTableML.length - (-9) ? 'none': '1px solid lightgray',borderBottom:'none'}}>{item.SOMA}</td>
                                    ))}                              
                                </tr>
                            </tbody>
                            </Table> 
                        </Card>
                    </Col>
                </Row> {/* Termino Tabela Pag.ok ML */}
                <Row style={{height:"auto",marginTop:10,visibility:mostrarDados ? 'visible':'hidden'}}> {/* Inicio GraficosML */}
                    <Col xs={6}>
                        <Titulo style={{marginBottom:1,textAlign:'center'}}> VENDEDORES ML X USUARIOS AvantproML </Titulo>
                        <Card style={{height:"200px",marginTop:15,display:'flex',width:'100%',paddingRight:5,paddingLeft:5}}> 
                            <div style={{height:'80%',marginTop:'auto',marginBottom:'auto'}}>
                                <MLxAvantpro />
                            </div>                           
                            <div style={{marginTop:'auto',marginBottom:'auto',width:'100%',marginRight:10}}>
                                <TituloCard style={{display:"flex", alignItems:"baseline",justifyContent:'flex-end'}}><LegendaConv style={{backgroundColor:'#FFA200'}}/><h4 style={{fontWeight:400,display:'flex',flexDirection:"column",alignItems:'center',fontSize:15}}>Vendedores<span style={{fontWeight:500}}>10Milhões</span></h4></TituloCard>
                                <TituloCard style={{display:"flex", alignItems:"baseline",justifyContent:'flex-end'}}><LegendaConv style={{backgroundColor:'rgba(54, 162, 235, 1)'}}/><h4 style={{fontWeight:400,display:'flex',flexDirection:"column",alignItems:'center',fontSize:15}}>Usuários Avantpro<span style={{fontWeight:500}}>{dadosML.length && (<span key={dadosML[0].TOTAL}>{(dadosML[0].TOTAL).toLocaleString()}</span>)}</span></h4></TituloCard>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={6}>
                        <Titulo style={{marginBottom:1,textAlign:'center'}}> VENDEDORES CPF X VENDEDORES CNPJ </Titulo>
                        <Card style={{height:"200px",marginTop:15,display:'flex',width:'100%'}}> 
                            <div style={{height:'80%',marginTop:'auto',marginBottom:'auto'}}>
                                <CpfXCnpj dados={{
                                      dados: dadospfML
                                  }} />
                            </div>                           
                            <div style={{display: "grid", justifyContent: "center",alignItems:'center',justifyItems:'center',marginTop:'auto',marginBottom:'auto',width:'100%'}}>
                                <TituloCard style={{display:"flex", alignItems:"baseline"}}><LegendaConv style={{backgroundColor:'rgba(255, 99, 132, 1)'}}/><h4 style={{fontWeight:400,display:'flex',flexDirection:"column",alignItems:'center',fontSize:20}}>CPF<span style={{fontWeight:500}}>{dadospfML.length && (<span key={dadospfML[0].COUNT}>{(dadospfML[0].COUNT).toLocaleString()}</span>)}</span></h4></TituloCard>
                                <TituloCard style={{display:"flex", alignItems:"baseline"}}><LegendaConv style={{backgroundColor:'rgba(54, 162, 235, 1)'}}/><h4 style={{fontWeight:400,display:'flex',flexDirection:"column",alignItems:'center',fontSize:20}}>CNPJ<span style={{fontWeight:500}}>{dadospfML.length && (<span key={dadospfML[1].COUNT}>{(dadospfML[1].COUNT).toLocaleString()}</span>)}</span></h4></TituloCard>
                            </div>
                        </Card>
                    </Col>
                </Row>{/* Termino GraficosML */}                
                <Row style={{height:"auto",marginTop:10,visibility:mostrarDados ? 'visible':'hidden'}}> {/* Inicio Graf. Recorrencia ML*/}
                    <Col>
                        <Titulo style={{marginBottom:1,textAlign:'center'}}> RECORRÊNCIA ASSINANTES </Titulo>
                        <Card style={{borderRadius:15, marginTop:10,padding:"5px 5px",height:'600px'}}>
                            <RecorrenciaGrafML 
                            dados={{
                                dados:RecorrenciaML
                            }}/>
                        </Card>
                    </Col>
                </Row> {/* Inicio Graf. Recorrencia ML*/}
                <Row style={{height:"auto",marginTop:10,visibility:mostrarDados ? 'visible':'hidden'}}> {/* Inicio Graf. Influenciadores ML*/}
                    <Col>
                        <Titulo style={{marginBottom:1,textAlign:'center'}}> VENDAS INFLUENCIADORES </Titulo>
                        <Card style={{borderRadius:15, marginTop:10,padding:"5px 5px",display:'flex',height:'600px'}}>
                            <VendasInfluenciadores 
                                dados={{
                                    dados:AfiliaoML
                                }}/>
                        </Card>
                    </Col>
                </Row> {/* Inicio Graf. Influenciadores ML*/}
            </Col> {/* Termino DO AVANTPRO ML*/}
            <Col xs={6}> {/* COMEÇO DO AVANTPRO SH*/}
            <Header><LogoSHSVG /><h1 onClick={paraSH} style={{cursor:'pointer'}}> Avantpro SH</h1></Header>
                    <Col style={{display:loading ? 'flex':'none',justifyContent:'center',width:'100%',marginBottom:20}}> {/* Loading */}
                        <div className="spinner-border text-danger" role="status">
                            <span className="visually-hidden">Loading...</span> 
                        </div>
                        <span style={{marginLeft:20,marginTop:'auto',marginBottom:'auto'}}>Carregando Dados..</span>
                    </Col>
                <Row className="row-cols-1 row-cols-md-5 row-cols-sm-2" style={{height:"auto",visibility:mostrarDados ? 'visible':'hidden'}}> {/* Inicio Informaçoes Gerais SH*/}
                    {dadosSH.map(item => (
                        <Col style={{marginBottom:10,paddingRight:5}}>
                            <Card style={{paddingTop:10,marginBottom:1,marginRight:'auto',marginLeft:'auto'}}>
                                <h6 className="placeholder-glow" style={{color:'gray',marginBottom:1,fontSize:14}}>{(item.STATUSNAME).toUpperCase()}</h6>
                                <h4 className="placeholder-glow" style={{marginBottom:1,fontSize:20}}>{(item.TOTAL).toLocaleString()}</h4>
                            </Card>
                        </Col>
                        ))
                    }
                </Row> {/* Termino Informaçoes Gerais SH*/}
                <Row style={{height:"auto",visibility:mostrarDados ? 'visible':'hidden'}}> {/* Inicio da Tabela Assinantes/Plano/Status SH */}
                    <Col>
                    <Titulo style={{marginBottom:10,textAlign:'center'}}> TABELA ASSINANTES/PLANO/STATUS </Titulo>
                        <Card style={{borderRadius:15, marginTop:10,padding:"5px 5px"}}>
                            <Table className="table table-rounded" style={{width:'100%',marginRight:200,marginBottom:0}}>
                            <thead>
                                <tr style={{fontSize:10,textAlign:"center",verticalAlign:"middle"}}>
                                    <th style={{ borderRight: '1px solid lightgray'}} scope="col">#</th>
                                    <th style={{ borderRight: '1px solid lightgray'}} scope="col">PAGO</th>
                                    <th style={{ borderRight: '1px solid lightgray'}} scope="col">CANCELADO</th>
                                    <th style={{ borderRight: '1px solid lightgray'}} scope="col">REEMBOLSADO</th> 
                                    <th style={{ borderRight: '1px solid lightgray'}} scope="col">AGUARD. PAG</th>
                                    <th style={{ borderRight: '1px solid lightgray'}} scope="col">TRIAL</th>
                                    <th style={{ borderRight: '1px solid lightgray'}} scope="col">ATRASADO</th>
                                    <th style={{ borderRight: '1px solid lightgray'}} scope="col">SUSPENSO</th>
                                    <th style={{ borderRight: '1px solid lightgray'}} scope="col">FINALIZADO</th>
                                    <th scope="col">AGUAR. REEMB</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dadosTableSH.map((item,i) =>(
                                    <>
                                        <tr style={{fontSize:11,textAlign:"center",verticalAlign:"middle",fontWeight:500}}>
                                            <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableSH.length - 1 ? 'none': '',fontSize:12}} >{(item.TAG).replace('_', ".")}</td>
                                            <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableSH.length - 1 ? 'none': '',fontSize:12}} >{(item.PAGO)}</td>
                                            <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableSH.length - 1 ? 'none': '',fontSize:12}} >{(item.CANCELADO)}</td>
                                            <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableSH.length - 1 ? 'none': '',fontSize:12}} >{(item.REEMBOLSADO)}</td>
                                            <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableSH.length - 1 ? 'none': '',fontSize:12}} >{(item.AGUARDANDO_PAGAMENTO)}</td>
                                            <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableSH.length - 1 ? 'none': '',fontSize:12}} >{(item.TRIAL)}</td>
                                            <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableSH.length - 1 ? 'none': '',fontSize:12}} >{(item.ATRASADO)}</td>
                                            <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableSH.length - 1 ? 'none': '',fontSize:12}} >{(item.SUSPENSO)}</td>
                                            <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableSH.length - 1 ? 'none': '',fontSize:12}} >{(item.FINALIZADO)}</td>
                                            <td style={{ borderBottom:i === dadosTableSH.length - 1 ? 'none': '',fontSize:12}}>{(item.AGUARDANDO_REEMBOLSO)}</td>
                                        </tr>
                                    </>
                                ))}
                            </tbody>
                            </Table> 
                        </Card>
                    </Col>
                </Row> {/* Termino da Tabela Assinantes/Plano/Status SH */}
                <Row style={{height:"auto",marginTop:10,visibility:mostrarDados ? 'visible':'hidden'}}> {/* Inicio Tabela Novos Ass. SH*/}
                    <Col>
                        <Titulo style={{marginBottom:1,textAlign:'center'}}> NOVOS ASSINANTES NOS ÚLTIMOS DIAS </Titulo>
                        <Card style={{borderRadius:15, marginTop:10,padding:"5px 5px"}}>
                            <Table className="table table-rounded" style={{width:'100%',marginBottom:0}}>
                            <thead>
                                <tr style={{fontSize:10,textAlign:"center",verticalAlign:"middle"}} >
                                    <th style={{ borderRight: '1px solid lightgray',fontSize:13}}>2023</th>
                                    {assinantesSH.map((item,i) =>(
                                        <th style={{fontSize:12,borderRight:i === dadosTableML.length - (-9) ? 'none': '1px solid lightgray'}}>{(item.DATA).replace('/2023','')}</th>
                                    ))}
                               </tr>
                            </thead>
                            <tbody>
                                <tr style={{fontSize:10,textAlign:"center",verticalAlign:"middle"}} >
                                    <td style={{ borderRight: '1px solid lightgray',borderBottom:'none',fontSize:13,fontWeight:600}}>Novos Ass.</td>
                                    {assinantesSH.map((item,i) =>(
                                        <td style={{fontSize:12,borderRight:i === dadosTableML.length - (-9) ? 'none': '1px solid lightgray',borderBottom:'none'}}>{item.SOMA}</td>
                                    ))}                              
                                </tr>
                            </tbody>
                            </Table> 
                        </Card>
                    </Col>
                </Row> {/* Termino Tabela Novos Ass. SH */}
                <Row style={{height:"auto",marginTop:10,visibility:mostrarDados ? 'visible':'hidden'}}> {/* Inicio Graficos SH */}
                    <Col xs={6}>
                        <Titulo style={{marginBottom:1,textAlign:'center'}}> VENDEDORES SH X USUARIOS AvantproSH </Titulo>
                        <Card style={{height:"200px",marginTop:15,display:'flex',width:'100%',paddingRight:5,paddingLeft:5}}> 
                            <div style={{height:'80%',marginTop:'auto',marginBottom:'auto',display:'flex'}}>
                                <SHxAvantpro />
                            </div>
                            <div style={{marginTop:'auto',marginBottom:'auto',width:'100%',marginRight:10}}>
                                <TituloCard style={{display:"flex", alignItems:"baseline",justifyContent:"flex-end"}}><LegendaConv style={{backgroundColor:'#FE5F32'}}/><h4 style={{fontWeight:400,display:'flex',flexDirection:"column",alignItems:'center',fontSize:15}}>Vendedores<span style={{fontWeight:500}}>3Milhões</span></h4></TituloCard>
                                <TituloCard style={{display:"flex", alignItems:"baseline",justifyContent:"flex-end"}}><LegendaConv style={{backgroundColor:'rgba(54, 162, 235, 1)'}}/><h4 style={{fontWeight:400,display:'flex',flexDirection:"column",alignItems:'center',fontSize:15}}>Usuários Avantpro<span style={{fontWeight:500}}>{dadosSH.length && (<span key={dadosSH[0].TOTAL}>{(dadosSH[0].TOTAL).toLocaleString()}</span>)}</span></h4></TituloCard>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={6}>
                        <Titulo style={{marginBottom:1,textAlign:'center'}}> VENDEDORES CPF X VENDEDORES CNPJ </Titulo>
                        <Card style={{height:"200px",marginTop:15,display:'flex',width:'100%'}}> 
                            <div style={{height:'80%',marginTop:'auto',marginBottom:'auto'}}>
                                <CpfXCnpjSH dados={{
                                    dados: dadospfSH
                                }} />
                            </div>                           
                            <div style={{display: "grid", justifyContent: "center",alignItems:'center',justifyItems:'center',marginTop:'auto',marginBottom:'auto',width:'100%'}}>
                                <TituloCard style={{display:"flex", alignItems:"baseline"}}><LegendaConv style={{backgroundColor:'rgba(255, 99, 132, 1)'}}/><h4 style={{fontWeight:400,display:'flex',flexDirection:"column",alignItems:'center',fontSize:20}}>CPF <span style={{fontWeight:500}}>{dadospfSH.length && (<span key={dadospfSH[0].COUNT}>{(dadospfSH[0].COUNT).toLocaleString()}</span>)}</span></h4></TituloCard>
                                <TituloCard style={{display:"flex", alignItems:"baseline"}}><LegendaConv style={{backgroundColor:'rgba(54, 162, 235, 1)'}}/><h4 style={{fontWeight:400,display:'flex',flexDirection:"column",alignItems:'center',fontSize:20}}>CNPJ <span style={{fontWeight:500}}>{dadospfSH.length && (<span key={dadospfSH[1].COUNT}>{(dadospfSH[1].COUNT).toLocaleString()}</span>)}</span></h4></TituloCard>
                            </div>
                        </Card>
                    </Col>
                </Row>{/* Termino Graficos SH */}                
                <Row style={{height:"auto",marginTop:10,visibility:mostrarDados ? 'visible':'hidden'}}> {/* Inicio Graf. Influenciadores SH*/}
                    <Col>
                        <Titulo style={{marginBottom:1,textAlign:'center'}}> VENDAS INFLUENCIADORES </Titulo>
                        <Card style={{borderRadius:15, marginTop:10,padding:"5px 5px",display:'flex',height:'600px'}}>
                            <VendasInfluenciadoresSH 
                                dados={{
                                    dados:AfiliaoSH
                                }}/>
                        </Card>
                    </Col>
                </Row> {/* Inicio Graf. Influenciadores SH*/}             
            </Col>{/* TERMINO DO AVANTPRO SH*/}
        </Row>
    </Container>
  );
}

export default DashBoard;
