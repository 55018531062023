import styled from "styled-components";
import React from "react";
import SVG from "react-inlinesvg";

const mlLogo = require('../../assets/AvantproML.svg').default;
const shLogo = require('../../assets/AvantproSH.svg').default;

interface SVGProps {
    color: string;
}  

const StyledSVG = styled(SVG)<SVGProps>`
  width: 30px;
  height: 30px;
`;
const StyledSVG1 = styled(SVG)<SVGProps>`
  width: 30px;
  height: 30px;
`;

export function LogoMLSVG() {
    const color = "";
    return <><StyledSVG color={color} src={mlLogo} /></>
}
export function LogoSHSVG() {
    const color = "";
    return <><StyledSVG1 color={color} src={shLogo} /></>
}


export const ContainerML = styled.div`
    height: 100%;
    padding: 20px;
    border-right: 1px solid #000;

`;
export const ContainerSH = styled.div`
    height: 100%;
    padding: 20px;
    border-left: 1px solid #000;
`;

export const Header = styled.div`
    width: 100%;
    display:flex;
    justify-content: center;
    height: auto;
    align-items: sup;
    position: relative;
    
    h1{
        height: auto;
        margin-left: 20px;
        font-size:30px
    }
`;

export const Titulo = styled.div`
    font-size:16px;
    height: 20px;
    font-weight: 500;
`
export const Numero = styled.div`
    font-size:15px;
    font-weight: bold;
    height: 20px;

`

export const TituloCard = styled.div`
    font-weight: 500;
    margin-top: 6px;
    color: black;
`;

export const LegendaConv = styled.div`
    height: 15px;
    width: 15px;
    border-radius: 3px;
    margin-right: 8px;
`;

