import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import type { ChartOptions } from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface GrafInfluencerProps {
  dados: {
     dados: any[] ;   
  }
}

export const options:ChartOptions<'bar'> = {
  indexAxis: 'y' as const,
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  aspectRatio:0,
  plugins: {
    datalabels:{
      anchor: 'end',
      align: 'end',
      padding: -2,
      formatter: function (value:any, context:any) {
        const valueNovo = value.toLocaleString();
        return valueNovo;
      },
      font: {
          size: 11.5,
          lineHeight: 0,
          weight: 'bold',
      },       
    },
    legend: {
        display:false,
        position: 'right' as const,
    },
  },
  scales: {
    y: {
      grid: {
        display: false,
      }
    },
    x: {
      suggestedMax: 100,
      grid: {
        display: false,
      }
    }
  },
};

export function VendasInfluenciadoresSH(props: GrafInfluencerProps) {

  const [afiliado ,setAfiliado] = useState<any[]>([]);
  const [valor ,setValor] = useState<any[]>([]);

  useEffect(() => {
    const afiliadoLimpo = props.dados.dados.map(item => {
      return item.AFILIADO 
    })

    const valorLimpo = props.dados.dados.map(item => {
      return item.SOMA 
    })
  
    setAfiliado(afiliadoLimpo)
    setValor(valorLimpo)


}, [props.dados.dados]);

  return <Bar options={options} data={
    {
      labels: afiliado,
      datasets: [
        {
          label: 'Assinantes',
          data: valor,
          borderColor: '#F6412E',
          backgroundColor: '#FF6633',
        },
      ],
    }
  } />;
}
