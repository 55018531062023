import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js/auto';
import  Plugin  from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend, Plugin);

interface GrafInfluencerProps {
    dados: {
       dados: any[],
       color: any[];   
    }
  }

export const options: ChartOptions<'pie'> =  {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: 'right',
    },
    tooltip: {
      caretSize: 0,
      xAlign: 'left',
      yAlign: 'top',
      backgroundColor: '#FFFFFF',
      titleColor: '#3d3d3d',
      bodyColor: '#3d3d3d',
      borderColor: '#ececec',
      borderWidth: 1,
      padding: 8,
      titleMarginBottom: 8,
      titleFont:{
        weight: 'bold',
      }
    },
    datalabels: {
      formatter: function (value:any, context:any) {
        const dataset = context.chart.data.datasets[context.datasetIndex];
        const total = dataset.data.reduce((prev:any, curr:any) => prev + curr);
        const percentage = ((value / total) * 100).toFixed(0) + '%';
        return percentage;
      },
      color: 'white',
      backgroundColor: 'Black',
      borderRadius: 5,
      font: {
        size: 13,
        weight: 'bold',
      },
    },
  },
};

export function AssinaturasGraf(props: GrafInfluencerProps) {

  const [afiliado ,setAfiliado] = useState<any[]>([]);
  const [valor ,setValor] = useState<any[]>([]);

  useEffect(() => {
    const afiliadoLimpo = props.dados.dados.map(item => {
      return item.TAG 
    })

    const valorLimpo = props.dados.dados.map(item => {
      return item.PAGO 
    })
  
    setAfiliado(afiliadoLimpo)
    setValor(valorLimpo)


}, [props.dados.dados]);

  return <Pie options={options} data={
    {
      labels: afiliado,
      datasets: [
        {
          label: '',
          data: valor,
          borderColor: ['white'],
          backgroundColor: props.dados.color,
        },
      ],
    }
  } />;
}
