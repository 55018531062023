import styled from "styled-components";
import { TooltipBottom, TooltipTop } from "../Tooltip/Tooltip.style";

export const Card = styled.div`
    border-radius: 30px;
    border: none;
    background: #FFFFFF;
    position: relative;
    padding: .5rem 1rem;
    height: auto;
    width: 100%;
    align-items: center;
    overflow: hidden;
    
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    h1{
        font-size:30px;
        height: auto;
        margin-top:10px;
        margin-bottom:0px
    }
    h6{
        font-size: 14px;
        height: auto;
        font-weight: 500;
    }

    ::-webkit-scrollbar{
        transition: .3s;
        background: transparent;
        height: 10px;
    }
    
    ::-webkit-scrollbar-track{
        background: var(--grey);
        border-radius: 0px 0px 10px 10px;
    }

    ::-webkit-scrollbar-thumb{
        background: #FFBA42;
        border-radius: 0px 0px 10px 10px;
    }
        
    th:hover{
        ${TooltipTop} {
            opacity: 1;
            visibility: visible;
            bottom: 105%;
        }
    
        ${TooltipBottom} {
            opacity: 1;
            visibility: visible;
            top: 105%;
        }
    }

`