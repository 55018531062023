import { Col, Container, Row, Table } from "react-bootstrap"
import { Header, LegendaConv, LogoSHSVG, SetaSVG, Titulo, TituloCard } from "./style"
import { Card } from "../../components/card/Card"
import { SHxAvantpro } from "../../components/Grafico/SHxAvant"
import { VendasInfluenciadoresSH } from "../../components/Grafico/influenciadoresSH"
import { useEffect, useState } from "react"
import { CpfXCnpjSH } from "../../components/Grafico/pfxpjSH"
import { useNavigate } from "react-router-dom"
import { fetchDataSH } from "./PromisseAllSH"
import { TooltipBottom, TooltipTop } from "../../components/Tooltip/Tooltip.style"
import { LTVsh } from "../../components/Grafico/LTVsh"

export const PaginaSH = () => {

    const navegate = useNavigate();
    const [dadosSH, setDadosSH] = useState<any[]>([]);
    const [dadosTableSH, setdadosTableSH] = useState<any[]>([]);
    const [AfiliaoSH, SetAfiliadoSH] = useState<any[]>([]);
    const [assinantesSH, SetAssinantesSH] = useState<any[]>([]);
    const [dadospfSH, SetDadospfSH] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [mostrarDados, setMostrarDados] = useState(false);
    const [dadosSummit, setDadosSummit] = useState<any[]>([]);
    const [dadosLtv, setDadosLtv] = useState<any[]>([]);
    const [afiliadoNovoSH, setAfiliadoNovoSH] = useState<any[]>([]);

    useEffect(() => {
        async function getData() {
            const result = await fetchDataSH();
            console.log(result);

            setLoading(false)
            setMostrarDados(false)

            if(result.length > 0) {
                setMostrarDados(true)
            }

            setDadosSH(result[0].RESULT)
            setdadosTableSH(result[1].RESULT)
            SetAfiliadoSH(result[2].RESULT)
            SetAssinantesSH(result[3].RESULT)
            SetDadospfSH(result[4].RESULT)
            setDadosSummit(result[5].RESULT)
            setDadosLtv(result[6].RESULT)
            setAfiliadoNovoSH(result[7].RESULT)

        }
        getData();
 
    }, []);

    function paraML(){
        navegate("/ML");
    }

    return (
            <Container fluid >
            <Row className="pt-2" style={{position:"relative"}}>
                <Col> {/* COMEÇO DO AVANTPRO SH*/}
                    <Row > {/* Header*/} 
                        <Col xs={12} ><Header><LogoSHSVG /><h1> Avantpro SH</h1></Header></Col>
                        <Col xs={12} style={{display:'flex',justifyContent:'flex-end'}}><div style={{cursor:'pointer'}} onClick={paraML}> Dados MercadoLivre <SetaSVG /></div></Col>
                    </Row>
                        <Col style={{display:loading ? 'flex':'none',justifyContent:'center',width:'100%',marginBottom:20}}>
                            <div className="spinner-border text-danger" role="status">
                                <span className="visually-hidden">Loading...</span> 
                            </div>
                            <span style={{marginLeft:20,marginTop:'auto',marginBottom:'auto'}}>Carregando Dados..</span>
                        </Col>
                    <Row className="row-cols-1 row-cols-md-5 row-cols-sm-2" style={{height:"auto",visibility:mostrarDados ? 'visible':'hidden'}}> {/* Inicio Informaçoes Gerais SH*/}
                        {dadosSH.map(item => (
                            <Col style={{marginBottom:10,paddingRight:5}}>
                                <Card style={{paddingTop:10,marginBottom:1,marginRight:'auto',marginLeft:'auto'}}>
                                    <h6 className="placeholder-glow" style={{color:'gray',marginBottom:1,fontSize:14}}>{(item.STATUSNAME).toUpperCase()}</h6>
                                    <h4 className="placeholder-glow" style={{marginBottom:1,fontSize:20}}>{(item.TOTAL).toLocaleString()}</h4>
                                </Card>
                            </Col>
                            ))
                        }
                    </Row> {/* Termino Informaçoes Gerais SH*/}
                    <Row style={{height:"auto",visibility:mostrarDados ? 'visible':'hidden'}}> {/* Inicio da Tabela Assinantes/Plano/Status SH */}
                        <Col>
                        <Titulo style={{marginBottom:10,textAlign:'center'}}> TABELA ASSINANTES/PLANO/STATUS </Titulo>
                            <Card style={{borderRadius:15, marginTop:10,padding:"5px 5px",overflowX:'auto'}}>
                                <Table className="table table-rounded" style={{width:'100%',marginBottom:0}}>
                                <thead>
                                    <tr style={{fontSize:10,textAlign:"center",verticalAlign:"middle"}}>
                                        <th style={{ borderRight: '1px solid lightgray'}} scope="col">#</th>
                                        <th style={{ borderRight: '1px solid lightgray'}} scope="col">PAGO</th>
                                        <th style={{ borderRight: '1px solid lightgray'}} scope="col">CANCELADO</th>
                                        <th style={{ borderRight: '1px solid lightgray'}} scope="col">REEMBOLSADO</th> 
                                        <th style={{ borderRight: '1px solid lightgray'}} scope="col">AGUAR. PAG</th>
                                        <th style={{ borderRight: '1px solid lightgray'}} scope="col">TRIAL</th>
                                        <th style={{ borderRight: '1px solid lightgray'}} scope="col">ATRASADO</th>
                                        <th style={{ borderRight: '1px solid lightgray'}} scope="col">SUSPENSO</th>
                                        <th style={{ borderRight: '1px solid lightgray'}} scope="col">FINALIZADO</th>
                                        <th scope="col">AGUAR. REEMB</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dadosTableSH.map((item,i) =>(
                                        <>
                                            <tr style={{fontSize:11,textAlign:"center",verticalAlign:"middle",fontWeight:500}}>
                                                <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableSH.length - 1 ? 'none': '',fontSize:12}} >{(item.TAG).replace('_', ".")}</td>
                                                <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableSH.length - 1 ? 'none': '',fontSize:12}} >{(item.PAGO)}</td>
                                                <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableSH.length - 1 ? 'none': '',fontSize:12}} >{(item.CANCELADO)}</td>
                                                <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableSH.length - 1 ? 'none': '',fontSize:12}} >{(item.REEMBOLSADO)}</td>
                                                <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableSH.length - 1 ? 'none': '',fontSize:12}} >{(item.AGUARDANDO_PAGAMENTO)}</td>
                                                <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableSH.length - 1 ? 'none': '',fontSize:12}} >{(item.TRIAL)}</td>
                                                <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableSH.length - 1 ? 'none': '',fontSize:12}} >{(item.ATRASADO)}</td>
                                                <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableSH.length - 1 ? 'none': '',fontSize:12}} >{(item.SUSPENSO)}</td>
                                                <td style={{ borderRight: '1px solid lightgray',borderBottom:i === dadosTableSH.length - 1 ? 'none': '',fontSize:12}} >{(item.FINALIZADO)}</td>
                                                <td style={{ borderBottom:i === dadosTableSH.length - 1 ? 'none': '',fontSize:12}}>{(item.AGUARDANDO_REEMBOLSO)}</td>
                                            </tr>
                                        </>
                                    ))}
                                </tbody>
                                </Table> 
                            </Card>
                        </Col>
                    </Row> {/* Termino da Tabela Assinantes/Plano/Status SH */}
                    <Row style={{height:"auto",marginTop:20,visibility:mostrarDados ? 'visible':'hidden'}}> {/* Inicio da Tabela MRR - CHRUN - CAC*/}
                        <Col>
                        <Titulo style={{marginBottom:10,textAlign:'center'}}> MRR - CHURN - CAC - ATIVOS - CANCELAMENTOS </Titulo>
                            <Card style={{borderRadius:'15px 10px', marginTop:10,padding:"5px 5px",overflowX:'auto'}}>
                                <Table className="table table-rounded" style={{marginBottom:0}}>
                                <thead>
                                    <tr style={{fontSize:10,textAlign:"center",verticalAlign:"middle"}}>
                                        <th style={{ borderRight: '1px solid lightgray'}} scope="col">#</th>
                                        {dadosSummit.map((item,i) =>(
                                            <th style={{ borderRight:i === dadosSummit.length - 1 ? 'none': '1px solid lightgray',color:i === dadosSummit.length - 1 ? '#FC9F2D': 'Black'}} scope="col">{item.MES}/{item.ANO}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{fontSize:11,textAlign:"center",verticalAlign:"middle",fontWeight:500}}>
                                        <td style={{ borderRight: '1px solid lightgray',borderBottom: '1px solid lightgray',fontSize:12}} >MRR</td>
                                        {dadosSummit.map((item,i) =>(
                                            <th style={{ borderRight:i === dadosSummit.length - 1 ? 'none': '1px solid lightgray',color:i === dadosSummit.length - 1 ? '#FC9F2D': 'Black'}} scope="col">{Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(item.MRR)}</th>
                                        ))}
                                    </tr>
                                    <tr style={{fontSize:11,textAlign:"center",verticalAlign:"middle",fontWeight:500}}>
                                        <td style={{ borderRight: '1px solid lightgray',borderBottom: '1px solid lightgray',fontSize:12}} >ATIVOS</td>
                                        {dadosSummit.map((item,i) =>(
                                            <th id={item.CODIGO}  style={{ borderRight:i === dadosSummit.length - 1 ? 'none': '1px solid lightgray',position:"relative",color:i === dadosSummit.length - 1 ? '#FC9F2D': 'Black'}} scope="col"><TooltipTop style={{display:'grid',fontSize:12}}> <span style={{fontWeight:500}}>Ativos Referentes ao mês Anterior</span> <span style={{fontWeight:500}}>Ativos Anual: {Intl.NumberFormat('pt-br').format(item.VENDASA)}</span> <span style={{fontWeight:500}}>Ativo Mensal: {Intl.NumberFormat('pt-br').format(item.VENDASM)}</span></TooltipTop>{Intl.NumberFormat('pt-br').format(item.VENDAS)}</th>                                    
                                        ))}
                                    </tr>
                                    <tr style={{fontSize:11,textAlign:"center",verticalAlign:"middle",fontWeight:500}}>
                                        <td style={{ borderRight: '1px solid lightgray',borderBottom: '1px solid lightgray',fontSize:12}} >CANCELADOS</td>
                                        {dadosSummit.map((item,i) =>(
                                            <th id={item.CODIGO}  style={{ borderRight:i === dadosSummit.length - 1 ? 'none': '1px solid lightgray',position:"relative",color:i === dadosSummit.length - 1 ? '#FC9F2D': 'Black'}} scope="col"><TooltipBottom style={{display:'grid',fontSize:12}}> <span style={{fontWeight:500}}>Canc. Referentes ao mês Corrente</span> <span style={{fontWeight:500}}>Canc. Anual: {Intl.NumberFormat('pt-br').format(item.CANCELADOA)}</span> <span style={{fontWeight:500}}>Canc. Mensal: {Intl.NumberFormat('pt-br').format(item.CANCELADOM)}</span></TooltipBottom>{Intl.NumberFormat('pt-br').format(item.CANCELADO)}</th>                                    
                                        ))}
                                    </tr>
                                    <tr style={{fontSize:11,textAlign:"center",verticalAlign:"middle",fontWeight:500}}>
                                        <td style={{ borderRight: '1px solid lightgray',borderBottom: '1px solid lightgray',fontSize:12}} >CHURN</td>
                                        {dadosSummit.map((item,i) =>(
                                            <th style={{ borderRight:i === dadosSummit.length - 1 ? 'none': '1px solid lightgray',color:i === dadosSummit.length - 1 ? '#FC9F2D': 'Black'}} scope="col">{item.CHURN}%</th>
                                        ))}
                                    </tr>
                                    <tr style={{fontSize:11,textAlign:"center",verticalAlign:"middle",fontWeight:500}}>
                                        <td style={{ borderRight: '1px solid lightgray',borderBottom: 'none',fontSize:12}} >CAC</td>
                                        {dadosSummit.map((item,i) =>(
                                            <th style={{ borderRight:i === dadosSummit.length - 1 ? 'none': '1px solid lightgray',borderBottom: 'none',color:i === dadosSummit.length - 1 ? '#FC9F2D': 'Black'}} scope="col">{Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(item.CAC)}</th>
                                        ))}
                                    </tr>
                                </tbody>
                                </Table> 
                            </Card>
                        </Col>
                    </Row>{/* Termino da Tabela MRR - CHRUN - CAC */}
                    <Row style={{height:"auto",marginTop:10,visibility:mostrarDados ? 'visible':'hidden'}}> {/* Inicio Tabela Novos Ass. SH*/}
                    <Col>
                        <Titulo style={{marginBottom:1,textAlign:'center'}}> NOVOS ASSINANTES NOS ÚLTIMOS 14 DIAS </Titulo>
                        <Card style={{borderRadius:15, marginTop:10,padding:"5px 5px",overflowX:'auto'}}>
                            <Table className="table table-rounded" style={{width:'100%',marginBottom:0}}>
                            <thead>
                                <tr style={{fontSize:10,textAlign:"center",verticalAlign:"middle"}} >
                                    <th style={{ borderRight: '1px solid lightgray',fontSize:13}}>2023</th>
                                    {assinantesSH.map((item,i) =>(
                                        <th style={{fontSize:12,borderRight:i === dadosTableSH.length - (-11) ? 'none': '1px solid lightgray'}}>{(item.DATA).replace('/2023','')}</th>
                                    ))}
                               </tr>
                            </thead>
                            <tbody>
                                <tr style={{fontSize:10,textAlign:"center",verticalAlign:"middle"}} >
                                    <td style={{ borderRight: '1px solid lightgray',borderBottom:'none',fontSize:13,fontWeight:600}}>NOVOS ASSINANTES</td>
                                    {assinantesSH.map((item,i) =>(
                                        <td style={{fontSize:12,borderRight:i === dadosTableSH.length - (-11) ? 'none': '1px solid lightgray',borderBottom:'none'}}>{item.SOMA}</td>
                                    ))}                              
                                </tr>
                            </tbody>
                            </Table> 
                        </Card>
                    </Col>
                    </Row> {/* Termino Tabela Novos Ass. SH */}
                    <Row className="row-cols-1 row-cols-md-2" style={{height:"auto",marginTop:10,visibility:mostrarDados ? 'visible':'hidden'}}> {/* Inicio Graficos SH */}
                        <Col>
                            <Titulo style={{marginBottom:1,textAlign:'center'}}> VENDEDORES SH X USUARIOS AvantproSH </Titulo>
                            <Card style={{height:"200px",marginTop:15,display:'flex',width:'100%',paddingRight:5,paddingLeft:5}}> 
                                <div style={{height:'80%',marginTop:'auto',marginBottom:'auto'}}>
                                    <SHxAvantpro />
                                </div>                           
                                <div style={{display: "grid", justifyContent: "center",alignItems:'center',justifyItems:'center',marginTop:'auto',marginBottom:'auto',width:'100%'}}>
                                    <TituloCard style={{display:"flex", alignItems:"baseline"}}><LegendaConv style={{backgroundColor:'#FE5F32'}}/><h4 style={{fontWeight:400,display:'flex',flexDirection:"column",alignItems:'center',fontSize:15}}>Vendedores SH <span style={{fontWeight:500}}>3Milhões</span></h4></TituloCard>
                                    <TituloCard style={{display:"flex", alignItems:"baseline"}}><LegendaConv style={{backgroundColor:'rgba(54, 162, 235, 1)'}}/><h4 style={{fontWeight:400,display:'flex',flexDirection:"column",alignItems:'center',fontSize:15}}>Usuários AvantproSH <span style={{fontWeight:500}}>{dadosSH.length && (<span key={dadosSH[0].TOTAL}>{(dadosSH[0].TOTAL).toLocaleString()}</span>)}</span></h4></TituloCard>
                                </div>
                            </Card>
                        </Col>
                        <Col>
                            <Titulo style={{marginBottom:1,textAlign:'center'}}> VENDEDORES CPF X VENDEDORES CNPJ </Titulo>
                            <Card style={{height:"200px",marginTop:15,display:'flex',width:'100%'}}> 
                                <div style={{height:'80%',marginTop:'auto',marginBottom:'auto'}}>
                                    <CpfXCnpjSH dados={{
                                        dados: dadospfSH
                                    }} />
                                </div>                           
                                <div style={{display: "grid", justifyContent: "center",alignItems:'center',justifyItems:'center',marginTop:'auto',marginBottom:'auto',width:'100%'}}>
                                    <TituloCard style={{display:"flex", alignItems:"baseline"}}><LegendaConv style={{backgroundColor:'rgba(255, 99, 132, 1)'}}/><h4 style={{fontWeight:400,display:'flex',flexDirection:"column",alignItems:'center',fontSize:20}}>CPF <span style={{fontWeight:500}}>{dadospfSH.length && (<span key={dadospfSH[0].COUNT}>{(dadospfSH[0].COUNT).toLocaleString()}</span>)}</span></h4></TituloCard>
                                    <TituloCard style={{display:"flex", alignItems:"baseline"}}><LegendaConv style={{backgroundColor:'rgba(54, 162, 235, 1)'}}/><h4 style={{fontWeight:400,display:'flex',flexDirection:"column",alignItems:'center',fontSize:20}}>CNPJ <span style={{fontWeight:500}}>{dadospfSH.length && (<span key={dadospfSH[1].COUNT}>{(dadospfSH[1].COUNT).toLocaleString()}</span>)}</span></h4></TituloCard>
                                </div>
                            </Card>
                        </Col>
                    </Row>{/* Termino Graficos SH */}                   
                    <Row style={{height:"auto",marginTop:10,visibility:mostrarDados ? 'visible':'hidden'}}> {/* Inicio Graf. Influenciadores SH*/}
                        <Col>
                            <Titulo style={{marginBottom:1,textAlign:'center'}}> VENDAS INFLUENCIADORES </Titulo>
                            <Card style={{borderRadius:15, marginTop:10,padding:"5px 5px",display:'flex',height:'600px'}}>
                                <VendasInfluenciadoresSH 
                                    dados={{
                                        dados:AfiliaoSH
                                    }}/>
                            </Card>
                        </Col>
                        <Col >
                            <Titulo style={{marginBottom:1,textAlign:'center'}}> LTV </Titulo>
                            <Card style={{borderRadius:15, marginTop:10,padding:"5px 5PX",display:'flex',height:'600px'}}>
                                <LTVsh
                                    dados={{
                                        dados:dadosLtv
                                    }}/>
                            </Card>
                        </Col>
                    </Row> {/* Inicio Graf. Influenciadores SH*/}
                    <Row style={{height:"auto",marginTop:10,visibility:mostrarDados ? 'visible':'hidden'}}> {/* Inicio Graf. Influenciadores SH*/}
                        <Col>
                            <Titulo style={{marginBottom:1,textAlign:'center'}}> VENDAS AFILIADOS </Titulo>
                            <Card style={{borderRadius:15, marginTop:10,padding:"5px 5px",display:'flex',height:'600px'}}>
                                <VendasInfluenciadoresSH 
                                    dados={{
                                        dados:afiliadoNovoSH
                                    }}/>
                            </Card>
                        </Col>
                    </Row> {/* Inicio Graf. Influenciadores SH*/}          
                </Col>{/* TERMINO DO AVANTPRO SH*/}
            </Row>
        </Container>
    )
}
