import styled from "styled-components";


export const Tooltip = styled.div`
    opacity: 0;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.9);
    color: #fff;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    width: 200px;
    padding: 8px 4px;
    border-radius: 8px;
    font-size: 14px;
    text-align: center;
    position: absolute;
    z-index: 10;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;

    &::after{
        content: " ";
        position: absolute;
        border-width: 5px;
        border-style: solid;
    }

`;

export const TooltipTop = styled(Tooltip)`
    left: 50%;
    bottom: 95%;
    transform: translateX(-50%);

    &::after{
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-color: rgba(0, 0, 0, 0.9) transparent transparent transparent;
    }
`;

export const TooltipRight = styled(Tooltip)`
    top: 50%;
    right: 95%;
    transform: translateY(-50%);

    &::after{
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
        border-color: transparent transparent transparent rgba(0, 0, 0, 0.7);
    }
`;

export const TooltipBottom = styled(Tooltip)`
    top: 95%;
    left: 50%;
    transform: translateX(-50%);

    &::after{
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-color: transparent transparent rgba(0, 0, 0, 0.9) transparent;
    }
`;

export const TooltipLeft = styled(Tooltip)`
    top: 50%;
    left: 95%;
    transform: translateY(-50%);

    &::after{
        top: 50%;
        right: 100%;
        transform: translateY(-50%);
        border-color: transparent rgba(0, 0, 0, 0.7) transparent transparent;
    }
`;