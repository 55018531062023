export async function fetchDataSH(): Promise<any[]> {
    const options = {method: 'GET'};

    const dadosSH = fetch(process.env.REACT_APP_BASE_URL + '/v1/totalizador?site=SH', options)

    const dadosTableSH = fetch(process.env.REACT_APP_BASE_URL + '/v1/tabelaportipo?site=SH', options)

    const afiliadoSH = fetch(process.env.REACT_APP_BASE_URL + '/v1/totalizadorporafiliado?site=SH', options)

    const assinantesSH = fetch(process.env.REACT_APP_BASE_URL + '/v1/totalizadorsetedias?site=SH', options)

    const pfpjSH = fetch(process.env.REACT_APP_BASE_URL + '/v1/totalizadorpfpj?site=SH', options)

    const webSumit = fetch(process.env.REACT_APP_BASE_URL + '/v1/totalizadormes?site=SH', options)

    const ltv = fetch(process.env.REACT_APP_BASE_URL + '/v1/totalizadorltv?site=SH', options)

    const afiliadossh = fetch(process.env.REACT_APP_BASE_URL + '/v1/totalizadorafiliado?site=SH', options)

    const todosDados = await Promise.all([dadosSH,dadosTableSH,afiliadoSH,assinantesSH,pfpjSH,webSumit,ltv,afiliadossh]);

    const results = await Promise.all(todosDados.map(response => response.json()));

    return results;
  }