import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import type {  ChartOptions } from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


interface GrafBuscadorProps {
  dados: {
     dados: any[];   
  }
}

export const options:ChartOptions<'bar'> = {
  
  indexAxis: 'y' as const,
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  aspectRatio:0,
  plugins: {
    datalabels:{
      anchor: 'end',
      align: 'right',
      padding: -1,
      formatter: function (value:any, context:any) {
        const valueNovo = value.toLocaleString();
        return valueNovo;
      },
      font: {
          size: 12,
          lineHeight: 0,
          weight: 'bold',
      },       
    },
    legend: {
        display:false,
      position: 'right' as const,
    },
  },
  scales: {
    y: {
      title:{
        display: true,
        text:'MÊS / MESES',
        font: {
          family:'roboto',
          weight: '500',
        },
      },
      grid: {
        display: false,
      }
    },
    x: {
      title:{
        display: true,
        text:'ASSINANTES',
        font: {
          family:'roboto',
          weight: '500',
        },
      },
      suggestedMax: 3000,
      grid: {
        display: false,
      }
    }
  },
};

export function LTVml(props: GrafBuscadorProps) {

  const [meses ,setMeses] = useState<any[]>([]);
  const [valor ,setValor] = useState<any[]>([]);

  useEffect(() => {
    const mesesLimpo = props.dados.dados.map(item => {
      return item.MESES 
    })

    const valorLimpo = props.dados.dados.map(item => {
      return item.SOMA 
    })
  
    setMeses(mesesLimpo)
    setValor(valorLimpo)


}, [props.dados.dados]);


  return <Bar options={options} data={
     {
      labels: meses,
      datasets: [
        {
          label: 'Assinantes',
          data: valor,
          borderColor: '#f2a008',
          backgroundColor: '#f8c536',
        },
      ],
    }
  } />;
}
