import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js/auto';
import  Plugin  from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend, Plugin);

export const data = {
  labels: ['Vendedores ML', 'Usuarios Avantpro'],
  datasets: [
    {
      label: '',
      data: [10000000,10812],
      backgroundColor: [
        '#FFA200',
        'rgba(54, 162, 235, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

export const options: ChartOptions<'pie'> =  {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      caretSize: 0,
      xAlign: 'left',
      yAlign: 'top',
      backgroundColor: '#FFFFFF',
      titleColor: '#3d3d3d',
      bodyColor: '#3d3d3d',
      borderColor: '#ececec',
      borderWidth: 1,
      padding: 8,
      titleMarginBottom: 8,
      titleFont:{
        weight: 'bold',
      }
    },
    datalabels: {
      formatter: function (value:any, context:any) {
        const dataset = context.chart.data.datasets[context.datasetIndex];
        const total = dataset.data.reduce((prev:any, curr:any) => prev + curr);
        const percentage = ((value / total) * 100).toFixed(0) + '%';
        return percentage;
      },
      color: 'white',
      backgroundColor: 'Black',
      borderRadius: 5,
      font: {
        size: 13,
        weight: 'bold',
      },
    },
  },
};

export function MLxAvantpro() {
  return <Pie data={data} options={options}/>;
}

