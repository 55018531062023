import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js/auto';
import  Plugin  from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend, Plugin);

interface GrafInfluencerProps {
  dados: {
     dados: any[] ;   
  }
}

const options: ChartOptions<'doughnut'> = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      caretSize: 0,
      xAlign: 'left',
      yAlign: 'top',
      backgroundColor: '#FFFFFF',
      titleColor: '#3d3d3d',
      bodyColor: '#3d3d3d',
      borderColor: '#ececec',
      borderWidth: 1,
      padding: 8,
      titleMarginBottom: 8,
      titleFont: {
        weight: 'bold',
      },
    },
    datalabels: {
      formatter: function (value:any, context:any) {
        const dataset = context.chart.data.datasets[context.datasetIndex];
        const total = dataset.data.reduce((prev:any, curr:any) => prev + curr);
        const percentage = ((value / total) * 100).toFixed(0) + '%';
        return percentage;
      },
      color: 'white',
      backgroundColor: 'Black',
      borderRadius: 5,
      font: {
        size: 13,
        weight: 'bold',
      },
    },
  },
};

export function CpfXCnpjSH(props: GrafInfluencerProps) {

  const [tipo ,setTipo] = useState<any[]>([]);
  const [count ,setCount] = useState<any[]>([]);

  useEffect(() => {
    const afiliadoLimpo = props.dados.dados.map(item => {
      return item.TIPO 
    })

    const valorLimpo = props.dados.dados.map(item => {
      return item.COUNT 
    })
  
    setTipo(afiliadoLimpo)
    setCount(valorLimpo)


}, [props.dados.dados]);

  return <Doughnut data={{
    labels: tipo,
    datasets: [
      {
        label: '',
        data: count,
        backgroundColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
        ],
        borderWidth: 2,
      },
    ],
  }} options={options} />;
}
