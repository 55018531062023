export async function fetchDataML(): Promise<any[]> {
    const options = {method: 'GET'};

    const dadosML = fetch( process.env.REACT_APP_BASE_URL + '/v1/totalizador?site=ML', options)

    const dadosTableML = fetch(process.env.REACT_APP_BASE_URL + '/v1/tabelaportipo', options)

    const recorrenciaML = fetch(process.env.REACT_APP_BASE_URL + '/v1/totalizadorportempo', options)

    const afiliadoML = fetch(process.env.REACT_APP_BASE_URL + '/v1/totalizadorporafiliado', options)

    const assinantesML = fetch(process.env.REACT_APP_BASE_URL + '/v1/totalizadorsetedias', options)

    const pfpjML = fetch(process.env.REACT_APP_BASE_URL + '/v1/totalizadorpfpj?site=ML', options)

    const webSumit = fetch(process.env.REACT_APP_BASE_URL + '/v1/totalizadormes', options)

    const ltv = fetch(process.env.REACT_APP_BASE_URL + '/v1/totalizadorltv', options)

    const afiliadosML = fetch(process.env.REACT_APP_BASE_URL + '/v1/totalizadorafiliado', options)

    const todosDados = await Promise.all([dadosML, dadosTableML,recorrenciaML,afiliadoML,assinantesML,pfpjML,webSumit,ltv,afiliadosML]);

    const results = await Promise.all(todosDados.map(response => response.json()));

    return results;
  }